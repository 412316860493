import {
  Injectable
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {
  Observable,
  map,
  of
} from 'rxjs';
import {
  Data2Service
} from '../services/data2/data2.service';
import { Globals } from '../globals';
import { ConsentService } from '../services/consent.service';
import { HeadService } from '../services/head.service';
import { JsonldserviceService } from '../services/jsonldservice.service';

@Injectable({
  providedIn: 'root'
})

export class MasterGuard {


  constructor(
    private router: Router,
    private data2Service: Data2Service,
    private head: HeadService,
    private jsonLDService: JsonldserviceService) {}


  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {

    var h = new Headers();
    //     console.log("Headers:",h)
    var s = seo.find(x => state.url == x.route);


    if (s != null) {
        //console.log("Found matching SEO data:",state.url,s)
        this.head.setTitle(s.title);
        this.head.setDescription(s.description);
        this.head.setKeywords(s.keywords);
    } else {
        //console.warn("Missing matching SEO data for route:",state.url)
    }

    //console.log("MASTER GUARD > State.url > ",state.url)

    if (this.isWhitelisted(state.url)) {
        if (s != null) {
            this.jsonLDService.removeStructuredData();
            this.jsonLDService.insertSchema(this.jsonLDService.getPageSchema(s.title, "https://www.ifa.com" + s.route, s.description))
        }
        //        console.log(`Route '${state.url}' is whitelisted to be seen without consent.`);        
        //console.log("pagesThatDoNotContainHypotheticalBacktestedData.indexOf(state.url)>-1",pagesThatDoNotContainHypotheticalBacktestedData.indexOf(state.url)>-1, state.url)
        return true;
    } else {
        //console.log("MASTER AUTH GUARD > canActivateChild",state.url);
        return this.canActivate(childRoute, state);
    }
}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > {

  //STATIC LIST OF WHITELISTED CONTENT
  if (this.isWhitelisted(state.url)) {
      return of(true);
  }  

  //RECORD OF CONSENTING ALREADY EXISTS
  var ls = localStorage.getItem("consents");
  if (ls != null) {
      var lsp = JSON.parse(ls)
      //      console.log("lsp",lsp)
      if (lsp[0].answer == "true") {
          return of(true);
      }
  }

  //CHECK THE DATA2 API FOR WHITELISTED ARTICLE OR VIDEO
  var regexAV = "/(articles|videos)/([a-z0-9A-Z_-]+)/?"
  var matchesAV = state.url.match(regexAV);
  if (matchesAV != null) {
      return this.data2Service.articleVideoWhitelistCheck$(matchesAV[2]).pipe(map((wl: boolean) => {
          if (wl) {
              return true;
          } else {
              this.routeElsewhere2(state);
              return false;
          }
      }));
  } else {
      this.routeElsewhere2(state);
      return of(false);
  }
}
routeElsewhere2(state: RouterStateSnapshot) {
  localStorage.setItem("intercepted-route", JSON.stringify({
      url: state.url,
      date: new Date()
  }));
  var hasPreviousNavigation = Boolean(this.router.getCurrentNavigation()?.previousNavigation)
  //console.log("hasPreviousNavigation",hasPreviousNavigation)
  if (!hasPreviousNavigation) {
      var matches = pagesThatDoNotContainHypotheticalBacktestedData.filter(x => state.url.startsWith(x));
      //console.log("matches",matches)
      if (matches != null) {
          var longest = matches.reduce(
              function(a, b) {
                  return a.length > b.length ? a : b;
              }
          );
          //console.log("longest",longest);
          setTimeout(() => {
              this.router.navigate([longest]);
          }, 1500)
      } else {
          this.router.navigate(['/']);
      }
      setTimeout(() => {
          //this.globals.signInModalEvents.emit(true); //MIKE A: 2023-02-10 As per Mark H we now just redirect to the consent page
          this.router.navigate(['/required-consent']);
      }, 2000)
      //console.log("in 2 seconds the globals.signInModalEvents will emit")
      //return false;
  } else {
      //this.globals.signInModalEvents.emit(true); //MIKE A: 2023-02-10 As per Mark H we now just redirect to the consent page
      this.router.navigate(['/required-consent']);
      //console.log("the globals.signInModalEvents will emit")
      //return false;
  }
}

  isWhitelisted(r: string) {
    return (pagesThatDoNotContainHypotheticalBacktestedData.indexOf(r) > -1 ||
      r.indexOf("/quotes/") == 0);
  }
}

//WHITELIST - ALL PAGES REQUIRE CONSENT EXCEPT THESE:
//ALPHABETICALLY LISTED
//NO PAGES BELOW SHOULD HAVE ANY HYPOTHETICAL BACKTESTED DATA / CHARTS / VIDEOS etc.
var pagesThatDoNotContainHypotheticalBacktestedData = [
  "/",
  "/404",
  "/about",
  "/about/advisors",
  "/books",
  "/client-experience",
  "/contact",
  "/disclosures",
  "/required-consent",
  "/investment-philosophy"
]

export class IFAMeta {
  route = "";
  title = "";
  description = "";
  keywords = "";
  constructor(_route: string, _title: string, _keywords: string, _description: string) {
      this.route = _route;
      this.title = _title;
      this.keywords = _keywords;
      this.description = _description;
  }
}

var seo = [
  new IFAMeta('/', 'IFA Institutional - A Division of Index Fund Advisors, Inc. - Fiduciary Wealth Services, Dimensional Funds', 'Index Funds, Financial Advisor', 'Index Fund Advisors is a fee-only independent fiduciary financial advisor that specializes in risk-appropriate portfolios of index funds.'),
]